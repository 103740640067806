
import { defineComponent } from 'vue';
import QHomeQRCode from '@/components/image/qrcode/QHomeQRCode.vue';

export default defineComponent({
  name: 'RenewalNoticePopup',
  components: {
    QHomeQRCode,
  },
});
